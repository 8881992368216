import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TextField, Button, InputLabel, FormControl, Select } from '@material-ui/core';
import { setNpiInfo, setData } from '../../redux/dataSlice';
import { states, baseApiUrl } from '../../config';

const NpiLookupComponent = (props) => {
  const [knowsNPI, setKnowsNPI] = React.useState(null);
  const [lookupFields, setLookupFields] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [hcpList, setHcpList] = React.useState(null);

  const updateLookupFields = (field) => {
    return (event) => {
      setLookupFields({ ...lookupFields, [field]: event.target.value });
    };
  };

  const validateNpi = (value) => {
    return /^\d+$/.test(value) && value.length === 10;
  };

  if (props.isLicensed === null) {
    return (
      <div>
        <p>Are you a prescribing healthcare provider (ex. MD, DO, NP, PA)?</p>
        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            className="hlx-button"
            onClick={() => props.setData({ isLicensed: true })}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            className="hlx-button"
            onClick={() => {
              props.setData({ isLicensed: false, npiInfo: {} });
            }}
          >
            No
          </Button>
        </div>
      </div>
    );
  }

  const lookupHcp = async () => {
    const isValid = validateFields();
    const baseUrl = `${baseApiUrl}/npilookup?`;
    const params = Object.keys(_.pickBy(lookupFields, _.identity))
      .map((key) => key + '=' + lookupFields[key])
      .join('&');

    // console.log(`${baseUrl}${params}`);

    if (isValid) {
      setFormErrors({});
      setLoading(true);
      const req = fetch(`${baseUrl}${params}`);
      req.then((response) => {
        response.json().then((json) => {
          setHcpList(json.results);
          setLoading(false);
        });
      });
      req.catch((err) => {
        console.log('err on getting api is', err);
        setLoading(false);
      });
    }
  };

  const validateFields = () => {
    const errors = {};
    if (knowsNPI && lookupFields.number && !validateNpi(lookupFields.number)) {
      errors.number = true;
    } else if (!knowsNPI) {
      ['first_name', 'last_name', 'state'].forEach((field) => {
        if (!lookupFields[field]) {
          errors[field] = true;
        }
      });
    }
    setFormErrors(errors);
    return _.isEmpty(errors);
  };

  const renderNextButton = () => {
    return (
      <Button
        variant="contained"
        className="hlx-button"
        onClick={() => {
          props.setData({ isLicensed: true, npiInfo: {} });
        }}
      >
        Continue to Registration Form
      </Button>
    );
  };

  const renderHcps = () => {
    if (hcpList && !hcpList.length) {
      return (
        <div>
          <div>None found.</div>
          <br />
          <br />
          {renderNextButton()}
        </div>
      );
    }
    if (_.isArray(hcpList)) {
      const rows = _.map(hcpList, (hcp) => {
        const { basic, addresses, taxonomies } = hcp;
        return (
          <div style={{ display: 'flex', marginBottom: 25 }} key={hcp.number.toString()}>
            <div style={{ width: '15%', display: 'flex' }}>
              <Button
                style={{
                  backgroundColor: '#EFEFEF',
                  color: '#000000',
                  borderRadius: 3,
                  border: '1px solid #000000',
                  height: 30,
                  width: 75,
                  padding: '1px 6px'
                }}
                className="hlx-button"
                variant="contained"
                onClick={() => props.setNpiInfo(hcp)}
              >
                Select
              </Button>
            </div>
            <div style={{ width: '25%', paddingRight: '15px' }}>
              {hcp.number}
              <br />
              {`${basic.first_name} ${basic.middle_name} ${basic.last_name}`}
            </div>
            <div style={{ width: '35%', paddingRight: '15px' }}>
              {addresses[0].address_1}
              <br />
              {`${addresses[0].city} ${addresses[0].state}`}
            </div>
            <div style={{ width: '25%', paddingRight: '15px' }}>
              {`${taxonomies[0].state} ${taxonomies[0].license}`}
              <br />
              {taxonomies[0].desc}
            </div>
          </div>
        );
      });
      return (
        <div>
          <div style={{ marginBottom: 10 }}>
            Click Select next to the record that lists your information:
          </div>
          {rows}
          <div style={{ marginTop: 50, marginBottom: 20 }}>If you do not see your record</div>
          {renderNextButton()}
        </div>
      );
    }
  };

  const renderSearchButton = () => {
    if (loading) {
      return <p>loading...</p>;
    }
    return (
      <Button
        variant="contained"
        className="hlx-button"
        style={{ cursor: 'pointer' }}
        onClick={lookupHcp}
      >
        Search
      </Button>
    );
  };

  const renderNpiLookupFields = () => {
    if (knowsNPI !== null) {
      if (knowsNPI) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
            <TextField
              fullWidth
              style={{ marginBottom: 20, marginTop: 0 }}
              value={lookupFields.number || ''}
              onChange={updateLookupFields('number')}
              label={'*NPI'}
              error={formErrors.number}
              margin={'dense'}
              variant={'outlined'}
              type="number"
              helperText={formErrors['number'] && 'Invalid NPI'}
            />
            {renderSearchButton()}
          </div>
        );
      }

      return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
          <TextField
            fullWidth
            style={{ marginBottom: 20, marginTop: 0 }}
            value={lookupFields.first_name || ''}
            onChange={updateLookupFields('first_name')}
            error={formErrors.first_name}
            label={'*First Name'}
            margin={'dense'}
            variant={'outlined'}
          />
          <TextField
            fullWidth
            style={{ marginBottom: 20, marginTop: 0 }}
            value={lookupFields.last_name || ''}
            onChange={updateLookupFields('last_name')}
            error={formErrors.last_name}
            label={'*Last Name'}
            margin={'dense'}
            variant={'outlined'}
          />
          <div style={{ display: 'flex', marginBottom: 20 }}>
            <div style={{ paddingRight: 5, flex: 3 }}>
              <TextField
                fullWidth
                value={lookupFields.city || ''}
                onChange={updateLookupFields('city')}
                label={'City'}
                margin={'dense'}
                style={{ margin: 0 }}
                variant={'outlined'}
                inputProps={{
                  autoComplete: 'off'
                }}
              />
            </div>
            <div style={{ paddingLeft: 5, flex: 1 }}>
              <FormControl fullWidth>
                <InputLabel error={formErrors.state} htmlFor="state">
                  *State
                </InputLabel>
                <Select
                  native
                  variant={'outlined'}
                  value={lookupFields.state}
                  onChange={updateLookupFields('state')}
                  error={formErrors.state}
                  margin={'dense'}
                  style={{ margin: 0 }}
                  label="*State"
                  inputProps={{
                    name: 'state',
                    id: 'state'
                  }}
                >
                  <option aria-label="None" key="none" value="" />
                  {_.map(_.keys(states), (state) => {
                    return (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          {renderSearchButton()}
        </div>
      );
    }
  };

  const resetState = (knowsNpi, cancelButton = false) => {
    setKnowsNPI(knowsNpi);
    setLookupFields({});
    setHcpList(null);
    if (cancelButton) {
      props.setData({ isLicensed: null });
    }
  };

  return (
    <div className="npi-lookup-component">
      <div className="npi-lookup-btns">
        <Button variant="contained" className="hlx-button" onClick={() => resetState(true)}>
          I know my NPI Number
        </Button>
        <Button variant="contained" className="hlx-button" onClick={() => resetState(false)}>
          Look up my NPI Number
        </Button>
        <Button
          className="cancel-btn hlx-button"
          variant="contained"
          onClick={() => resetState(null, true)}
        >
          Cancel
        </Button>
      </div>
      <div className="npi-lookup-fields">{renderNpiLookupFields()}</div>
      <div>{renderHcps()}</div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { meetingInfo, isLicensed } = data;
  return { meetingInfo, isLicensed };
};

export default connect(mapStateToProps, { setNpiInfo, setData })(NpiLookupComponent);
