import React from 'react';
import moment from 'moment';

const FooterComponent = (props) => {
  return (
    <div className="footer">
      Copyright {moment().format('YYYY')} &copy; HealthLogiX, LLC. All Rights Reserved.
    </div>
  );
};

export default FooterComponent;
