import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Switch, Route } from 'react-router-dom';

import '../Styles/_app.css';

import InviteCodeComponent from './InviteCodeComponent';
import RegForm from './RegForm';

const App = () => (
  <Switch>
    <Route path="/" exact component={InviteCodeComponent} />
    <Route path="/:invitecode" component={RegForm} />
  </Switch>
);

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
