import React from 'react';

const HeaderComponent = (props) => {
  return (
    <div className="header">
      <img className="header-logo" src={require('../../assets/logo-hlx.png')} alt="Registration" />
      <div className="header-title">Event Registration</div>
    </div>
  );
};

export default HeaderComponent;
