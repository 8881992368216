import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { TextField, Button, InputLabel, FormControl, Select, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { setData } from '../../redux/dataSlice';
import { states, baseApiUrl, specialties, degrees } from '../../config';

const RegistrationFormComponent = (props) => {
  const { institutions, isLicensed, npiInfo, meetingInfo, setData, regSource } = props;

  const [formValues, setFormValues] = React.useState({
    meeting_id: meetingInfo.id,
    reg_source: regSource
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (isLicensed && !_.isEmpty(npiInfo)) {
      const npiData = {
        npi: npiInfo.number,
        firstname: npiInfo.basic.first_name,
        lastname: npiInfo.basic.last_name,
        minitial: npiInfo.basic.middle_name ? npiInfo.basic.middle_name[0] : ''
      };
      setFormValues({ ...formValues, ...npiData });
    }
  }, []);

  const validateFields = () => {
    let requiredFields = [
      'firstname',
      'lastname',
      'specialty',
      'orgname',
      'address1',
      'city',
      'state',
      'zip',
      'phone',
      'email',
      'degree',
      'role'
    ];
    if (isLicensed) {
      const reqFields = ['npi'];
      if (formValues.degree === 'OTHER') {
        requiredFields.push('other_degree');
      }
      requiredFields = requiredFields.concat(reqFields);
    } else {
      //requiredFields.push('role');
      if (formValues.role === 'OTHER') {
        requiredFields.push('other_role');
      }
    }
    if (formValues.orgname === 'OTHER') {
      requiredFields.push('other_orgname');
    }

    const errors = {};
    requiredFields.forEach((field) => {
      if (!formValues[field]) {
        errors[field] = true;
      }
    });
    if (formValues.npi) {
      var x = formValues.npi.toString().length;
      if (!isLicensed && parseInt(x) !== 10) {
        errors['npi'] = true;
      }
    }

    if (formValues.email) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formValues.email)) {
        errors['email'] = true;
      }
    }

    setFormErrors(errors);
    return _.isEmpty(errors) ? true : false;
  };

  const submitForm = async () => {
    const isValid = validateFields();
    formValues.isLicensed = isLicensed;

    if (isValid) {
      setIsSubmitting(true);
      const req = await fetch(`${baseApiUrl}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formValues,
          specialty: specialties[formValues.specialty],
          degree: degrees[formValues.degree]
        })
      });
      setIsSubmitting(false);
      if (req.status === 201) {
        setSubmitted(true);
      } else if (req.status === 403) {
        alert('This meeting is at capacity.');
      } else {
        alert('Something went wrong with your submission.');
      }
    }
  };

  const updateFormFieldAutoComplete = (field) => {
    return (event, value) => {
      if (field === 'orgname') {
        const institution = _.find(institutions, (institution) => institution.name === value);
        if (institution === undefined) {
          return setFormValues({
            ...formValues,
            orgname: '',
            address1: '',
            city: '',
            state: '',
            zip: ''
          });
        }
        return setFormValues({
          ...formValues,
          orgname: institution.name,
          address1: institution.address,
          city: institution.city,
          state: institution.state,
          zip: institution.zip
        });
      }

      setFormValues({ ...formValues, [field]: value });
    };
  };

  const updateFormField = (field) => {
    return (event) => {
      if (field === 'email_consent') {
        return setFormValues({ ...formValues, [field]: event.target.checked });
      }

      setFormValues({ ...formValues, [field]: event.target.value });
    };
  };

  const renderStateLicenseNumber = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.npi || ''}
            onChange={updateFormField('npi')}
            label={'NPI'}
            error={formErrors.npi}
            margin={'dense'}
            variant={'outlined'}
            type="number"
          />
        </div>
        <div style={{ width: '50%', display: 'flex' }}>
          <FormControl fullWidth style={{ margin: 10 }}>
            <InputLabel htmlFor="state">Licensed State</InputLabel>
            <Select
              native
              variant={'outlined'}
              value={formValues.licensed_state || ''}
              onChange={updateFormField('licensed_state')}
              margin={'dense'}
              label="Licensed State"
              inputProps={{
                name: 'licensed_state',
                id: 'licensed_state',
                maxLength: 2
              }}
            >
              <option aria-label="None" key="none" value="" />
              {_.map(_.keys(states), (state) => {
                return (
                  <option key={state} value={state}>
                    {state}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.license_number || ''}
            onChange={updateFormField('license_number')}
            label={'License #'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 30 }}
          />
        </div>
      </div>
    );
  };

  const renderNpiFields = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <TextField
            disabled={'number' in npiInfo && /^\d{10}$/.test(npiInfo.number)}
            fullWidth
            style={{ margin: 10 }}
            value={formValues.npi || ''}
            onChange={updateFormField('npi')}
            label={'*NPI'}
            error={formErrors.npi}
            margin={'dense'}
            variant={'outlined'}
            type="number"
          />
        </div>
        <div style={{ width: '50%', display: 'flex' }}>
          <FormControl fullWidth style={{ margin: 10 }}>
            <InputLabel htmlFor="state">Licensed State</InputLabel>
            <Select
              native
              variant={'outlined'}
              value={formValues.licensed_state || ''}
              onChange={updateFormField('licensed_state')}
              margin={'dense'}
              label="Licensed State"
              inputProps={{
                name: 'licensed_state',
                id: 'licensed_state',
                maxLength: 2
              }}
            >
              <option aria-label="None" key="none" value="" />
              {_.map(_.keys(states), (state) => {
                return (
                  <option key={state} value={state}>
                    {state}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.license_number || ''}
            onChange={updateFormField('license_number')}
            label={'License #'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 30 }}
          />
        </div>
      </div>
    );
  };

  if (submitted) {
    return <h2>Thank you for submitting your registration!</h2>;
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.firstname || ''}
            onChange={updateFormField('firstname')}
            label={'*First Name'}
            margin={'dense'}
            variant={'outlined'}
            error={formErrors.firstname}
            inputProps={{ maxLength: 35 }}
          />
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.minitial || ''}
            onChange={updateFormField('minitial')}
            label={'Middle Name Initial'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 1 }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.lastname || ''}
            onChange={updateFormField('lastname')}
            label={'*Last Name'}
            margin={'dense'}
            variant={'outlined'}
            error={formErrors.lastname}
            inputProps={{ maxLength: 35 }}
          />
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.suffix || ''}
            onChange={updateFormField('suffix')}
            label={'Suffix'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 75 }}
          />
        </div>
        <FormControl style={{ marginLeft: 10, marginRight: 10 }}>
          <Autocomplete
            id="specialty"
            options={Object.keys(specialties)}
            value={formValues.specialty || ''}
            onChange={updateFormFieldAutoComplete('specialty')}
            renderInput={(params) => (
              <TextField
                {...params}
                error={formErrors.specialty}
                style={{ marginTop: 10, marginBottom: 10 }}
                margin={'dense'}
                label="*Specialty"
                variant="outlined"
              />
            )}
          />
        </FormControl>

        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <FormControl fullWidth style={{ padding: 10 }}>
              <Autocomplete
                id="degree"
                options={Object.keys(degrees)}
                value={formValues.degree || ''}
                onChange={updateFormFieldAutoComplete('degree')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formErrors.degree}
                    style={{ margin: 0 }}
                    margin={'dense'}
                    label="*Degree"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            {formValues.degree === 'OTHER' && (
              <TextField
                fullWidth
                value={formValues.other_degree || ''}
                onChange={updateFormField('other_degree')}
                label={'*Other Degree'}
                error={formErrors.other_degree}
                margin={'dense'}
                variant={'outlined'}
                inputProps={{ maxLength: 50 }}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ padding: 10 }}>
              <FormControl fullWidth>
                <InputLabel error={formErrors.role} htmlFor="role">
                  *Identify the Role that best describes you
                </InputLabel>
                <Select
                  native
                  variant={'outlined'}
                  value={formValues.role}
                  onChange={updateFormField('role')}
                  error={formErrors.role}
                  label="*Identify the Role that best describes you"
                  margin={'dense'}
                  inputProps={{
                    name: 'role',
                    id: 'role',
                    maxLength: 50
                  }}
                >
                  <option aria-label="None" key="none" value="" />
                  {_.map(
                    [
                      'Microbiologist',
                      'Nurse',
                      'Nurse Practitioner',
                      'Office Staff',
                      'Pharmacy',
                      'Physician',
                      'Physician Assistant',
                      'Student',
                      'Other'
                    ],
                    (role) => {
                      return (
                        <option key={role} value={role}>
                          {role}
                        </option>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <FormControl style={{ marginLeft: 10, marginRight: 10 }}>
          <Autocomplete
            id="orgname"
            options={institutions.map((institution) => institution.name)}
            filterOptions={(options, { inputValue }) => {
              let filteredOptions = options.filter((option) =>
                option.toLowerCase().includes(inputValue.toLowerCase())
              );
              if (!filteredOptions.includes('OTHER')) {
                filteredOptions.push('OTHER');
              }
              return filteredOptions;
            }}
            onChange={updateFormFieldAutoComplete('orgname')}
            value={formValues.orgname || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                error={formErrors.orgname}
                style={{ marginTop: 10, marginBottom: 10 }}
                margin={'dense'}
                label="*Affiliation"
                variant="outlined"
              />
            )}
          />
        </FormControl>
        {formValues.orgname === 'OTHER' && (
          <TextField
            style={{ margin: 10 }}
            value={formValues.other_orgname || ''}
            onChange={updateFormField('other_orgname')}
            label={'*Other Affiliation'}
            error={formErrors.other_orgname}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 100 }}
          />
        )}
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.address1 || ''}
            onChange={updateFormField('address1')}
            label={'*Office Address'}
            error={formErrors.address1}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.address2 || ''}
            onChange={updateFormField('address2')}
            label={'Address 2'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10, flex: 3 }}
            value={formValues.city || ''}
            onChange={updateFormField('city')}
            label={'*City'}
            error={formErrors.city}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 50 }}
          />
          <FormControl style={{ margin: 10, flex: 1 }}>
            <InputLabel error={formErrors.state} htmlFor="state">
              *State
            </InputLabel>
            <Select
              native
              variant={'outlined'}
              value={formValues.state || ''}
              onChange={updateFormField('state')}
              error={formErrors.state}
              margin={'dense'}
              label="*State"
              inputProps={{
                name: 'state',
                id: 'state',
                maxLength: 2
              }}
            >
              <option aria-label="None" key="none" value="" />
              {_.map(_.keys(states), (state) => {
                return (
                  <option key={state} value={state}>
                    {state}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            style={{ margin: 10, flex: 3 }}
            value={formValues.zip || ''}
            onChange={updateFormField('zip')}
            label={'*Zip'}
            error={formErrors.zip}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ minLength: 5, maxLength: 10 }}
            type="number"
          />
        </div>
        {isLicensed && renderNpiFields()}
        {!isLicensed && renderStateLicenseNumber()}
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.phone || ''}
            onChange={updateFormField('phone')}
            label={'*Office Phone'}
            error={formErrors.phone}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 15 }}
            type="tel"
          />
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.fax || ''}
            onChange={updateFormField('fax')}
            label={'Fax'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 15 }}
            type="tel"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.home_phone || ''}
            onChange={updateFormField('home_phone')}
            label={'Home Phone'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 15 }}
            type="tel"
          />
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.email || ''}
            onChange={updateFormField('email')}
            label={'*Email'}
            error={formErrors.email}
            helperText={formErrors.email && 'Invalid email address'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 100 }}
            type="email"
          />
        </div>
        <div style={{ padding: 10 }}>
          <TextField
            fullWidth
            multiline
            rows={5}
            value={formValues.dietary_constraints || ''}
            onChange={updateFormField('dietary_constraints')}
            label={'Dietary Constraints, optional, not all programs include a meal'}
            margin={'dense'}
            style={{ margin: 0 }}
            variant={'outlined'}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div style={{ padding: 10 }}>
          <TextField
            fullWidth
            multiline
            rows={5}
            value={formValues.comments || ''}
            onChange={updateFormField('comments')}
            label={'Comments'}
            margin={'dense'}
            style={{ margin: 0 }}
            variant={'outlined'}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div style={{ paddingLeft: 10 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.email_consent || false}
                onChange={updateFormField('email_consent')}
                name="email_consent"
              />
            }
            label="I give my consent to use my email address for communication involving this event"
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={{ width: '125px' }}
          className="hlx-button"
          variant="contained"
          disabled={isSubmitting}
          onClick={submitForm}
        >
          Register
        </Button>
        <Button
          className="cancel-btn hlx-button"
          variant="contained"
          onClick={() => setData({ npiInfo: null, isLicensed: null })}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { specialties, degrees, institutions, isLicensed, npiInfo, meetingInfo, regSource } = data;
  return { specialties, degrees, institutions, isLicensed, npiInfo, meetingInfo, regSource };
};
export default connect(mapStateToProps, { setData })(RegistrationFormComponent);
