import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { getMeetingInfo } from '../../redux/dataSlice';

const MeetingInfoComponent = (props) => {
  const { meetingInfo, getMeetingInfo } = props;
  const inviteCode = props.match.params.invitecode;

  React.useEffect(() => {
    if (!meetingInfo) {
      getMeetingInfo(inviteCode);
    }
  }, [meetingInfo, inviteCode, getMeetingInfo]);

  if (!meetingInfo) {
    return <CircularProgress />;
  }

  const { speaker, venue } = meetingInfo;

  return (
    <div className="meeting-info-component">
      <div className="title-text">Shionogi</div>
      <div className="disclaimer">
        In alignment with the revised PhRMA Code, beginning January 1, 2022, Shionogi will no longer
        pay for or provide alcoholic beverages at speaker programs. Individuals that wish to
        purchase alcohol may do so at their own expense.
      </div>
      <hr className="separator" />
      <div>
        <span className="bold">Event Information: </span>
      </div>
      <br />
      <div>
        <span className="bold">Topic: </span>
        {meetingInfo.title}
      </div>
      <br />
      <div>
        <span className="bold">Speaker: </span>
        {speaker.firstname} {speaker.lastname}
        <br />
        {speaker.title && <p>{speaker.title}</p>}
        {speaker.orgname && (
          <p>
            {speaker.orgname}
            <br />
          </p>
        )}
        {speaker.city} {speaker.state}
      </div>
      <br />
      <div>
        <span className="bold">When: </span>
        {moment(meetingInfo.startdatetime).format('LLLL')} -{' '}
        {moment(meetingInfo.enddatetime).format('LT')} {meetingInfo.timezone}
      </div>
      <br />
      <div>
        <span className="bold">Location: </span>
        {venue.name}
        <br />
        {venue.address1}
        <br />
        {venue.address2 && <p>{venue.address2}</p>}
        <br />
        {venue.city && (
          <span>
            {venue.city.trim()}, {venue.state.trim()} {venue.postalcode.trim()}
          </span>
        )}
        <br />
        {venue.phone && venue.phone}
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { meetingInfo } = data;

  return { meetingInfo };
};

export default withRouter(connect(mapStateToProps, { getMeetingInfo })(MeetingInfoComponent));
