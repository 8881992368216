export const getUrlParams = (name) => {
  const results = new RegExp(`${name}=([^&]*)`).exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]);
};

export const states = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const specialties = {
  'Abdominal Radiology': 'AR',
  'Abdominal Surgery': 'AS',
  'Acupuncture': 'ACU',
  'Acute Care Pediatrics (APNs only)': 'ACP',
  'Acute Care': 'AC',
  'Addiction Medicine': 'ADM',
  'Addiction Psychiatry': 'ADP',
  'Addiction Psychology & Counseling': 'PSU',
  'Administrative Medicine': 'AMD',
  'Adolescent Medicine (Family Medicine)': 'AMF',
  'Adolescent Medicine (Internal Medicine)': 'AMI',
  'Adolescent Medicine (Pediatrics)': 'ADL',
  'Adult Congenital Heart Disease (IM)': 'CHD',
  'Adult Health': 'ADU',
  'Adult Reconstructive Orthopedics': 'OAR',
  'Advanced Heart Failure & Transplant Card': 'HFT',
  'Advanced Heart Failure': 'AHF',
  'Advanced Practice Dentistry': 'DAP',
  'Advanced Surgical Oncology': 'ASO',
  'Aerospace Medicine': 'AM',
  'Allergy & Immunology': 'AI',
  'Allergy': 'A',
  'Anatomic Pathology': 'ATP',
  'Anatomic/Clinical Pathology': 'PTH',
  'Anesthesiology': 'AN',
  'Audiology': 'AUD',
  'Bariatric Medicine': 'BAR',
  'Biochemical & Medical Genetics': 'BMG',
  'Blood Banking /Transfusion Medicine': 'BBK',
  'Board Certified Sleep Medicine': 'SM',
  'Bone Marrow Transplantation': 'BMT',
  'Brain Injury Medicine (Phys Med & Rehab)': 'BIP',
  'Brain Injury Medicine (Psych & Neuro)': 'BIN',
  'Brd Cert Pediatric Transplant Hepatology': 'TPP',
  'Cardiothoracic Radiology': 'CTR',
  'Cardiovascular Disease': 'CD',
  'Chemical Pathology': 'PCH',
  'Child & Adolescent Psychiatry': 'CHP',
  'Child Abuse Pediatrics': 'CAP',
  'Child Neurology': 'CHN',
  'Chiropractic Medicine': 'CH',
  'Chiropractic Pediatrics': 'CH',
  'Clinical & Lab Dermatological Immunology': 'DDL',
  'Clinical & Laboratory Immunology (Ped)': 'PLI',
  'Clinical and Laboratory Immunology (IM)': 'ILI',
  'Clinical Biochemical Genetics': 'CBG',
  'Clinical Cardiac Electrophysiology': 'ICE',
  'Clinical Cytogenetics': 'CCG',
  'Clinical Genetics': 'CG',
  'Clinical Informatics (Anesthesiology)': 'IAN',
  'Clinical Informatics (Pathology)': 'CIP',
  'Clinical Informatics (Preventive Med)': 'CIM',
  'Clinical Informatics': 'CLI',
  'Clinical Lab Immunology (Allergy & Imm)': 'ALI',
  'Clinical Molecular Genetics': 'CMG',
  'Clinical Neurophysiology': 'CN',
  'Clinical Pathology': 'CLP',
  'Clinical Pharmacology': 'PA',
  'Clinical Psychology': 'PSC',
  'Colon & Rectal Surgery': 'CRS',
  'Complementary Medical Practice': 'CMP',
  'Congen Cardiac Surgery (Thoracic Surg)': 'CHS',
  'Correctional & Prison Medicine': 'CO',
  'Cosmetic & Aesthetic Dentistry': 'DEC',
  'Cosmetic Surgery': 'CS',
  'Craniofacial Surgery': 'CFS',
  'Critical Care Medicine (Anesthesiology)': 'CCA',
  'Critical Care Medicine (Internal Med)': 'CCM',
  'Critical Care Medicine (Ob & Gyn)': 'OCC',
  'Critical Care Medicine': 'CCE',
  'Critical Care Neurosurgery': 'CCN',
  'Cytopathology': 'PCP',
  'Dental Anesthesiology': 'DAN',
  'Dental Oncology': 'DEO',
  'Dental Public Health': 'DPH',
  'Dentistry': 'DEN',
  'Dermatologic Oncology': 'DON',
  'Dermatologic Surgery': 'DS',
  'Dermatology': 'D',
  'Dermatopathology': 'DMP',
  'Developmental-Behavioral Pediatrics': 'DBP',
  'Diabetes': 'DIA',
  'Diagnostic Radiology': 'DR',
  'Diagnostic Ultrasound': 'DU',
  'Dialysis': 'DYS',
  'Electrodiagnostic Medicine': 'EDM',
  'Emergency Dentistry': 'DEE',
  'Emergency Medical Services': 'EMS',
  'Emergency Medicine': 'EM',
  'Emergency Medicine/Anesthesiology': 'EMA',
  'Endo Surgical Neuroradiology (Neurology)': 'ENR',
  'Endo Surgical Neuroradiology (Radiology)': 'ESN',
  'Endocrinology, Diabetes, & Metabolism': 'END',
  'Endodontics': 'ED',
  'Endovascular Surgical Neuroradiology': 'ES',
  'Epidemiology': 'EP',
  'Epilepsy': 'EPL',
  'Facial Plastic Surgery': 'FPS',
  'Family Health (APNs)': 'FH',
  'Family Medicine - Preventive Medicine': 'FMP',
  'Family Medicine (Formerly FP)': 'FM',
  'Family Medicine/Psychiatry': 'FPP',
  'Family Practice (Residents Only)': 'FP',
  'Female Pelvic Med & Recon Surgery (URO)': 'UPR',
  'Female Pelvic Medicine & Recon Surgery': 'FPR',
  'Flex Year': 'FLX',
  'Foot & Ankle Orthopedic Medicine': 'POF',
  'Foot & Ankle Orthopedics': 'OFA',
  'Foot & Ankle Surgery': 'POG',
  'Forensic Odontology': 'FDN',
  'Forensic Pathology': 'FOP',
  'Forensic Psychiatry': 'PFP',
  'Gastroenterology': 'GE',
  'General Practice': 'GP',
  'General Preventive Medicine': 'GPM',
  'General Surgery': 'GS',
  'Genetics Counseling': 'CGC',
  'Geriatric Medicine (Family Practice)': 'FPG',
  'Geriatric Medicine (Internal Medicine)': 'IMG',
  'Geriatric Psychiatry': 'PYG',
  'Geriatrics/Gerontology': 'GER',
  'Glaucoma Specialist': 'GLAU',
  'Gynecologic_Family Planning': '5_3',
  'Gynecological Oncology': 'GO',
  'Gynecology': 'GYN',
  'Hand Surgery (Orthopedic)': 'HSO',
  'Hand Surgery (Plastic Surgery)': 'HSP',
  'Hand Surgery (Surgery)': 'HSS',
  'Hand Surgery': 'HS',
  'Head & Neck Surgery': 'HNS',
  'Hematology (Internal Medicine)': 'HEM',
  'Hematology (Pathology)': 'HMP',
  'Hematology/Oncology': 'HO',
  'Hepatobiliary Surgery': 'HBS',
  'Hepatology': 'HEP',
  'HIV-AIDS Medicine': 'HIV',
  'Homeopathy': 'HOM',
  'Hospice & Palliative Med (Anesth)': 'HPA',
  'Hospice & Palliative Med (PhysMed&Rehab)': 'HPR',
  'Hospice & Palliative Med (Psych&Neuro)': 'HPN',
  'Hospice & Palliative Med (Radiology)': 'HPD',
  'Hospice & Palliative Medicine (EM)': 'HPE',
  'Hospice & Palliative Medicine (FM)': 'HPF',
  'Hospice & Palliative Medicine (IM)': 'HPI',
  'Hospice & Palliative Medicine (ObGyn)': 'HPO',
  'Hospice & Palliative Medicine (Ped)': 'HPP',
  'Hospice & Palliative Medicine (Surgery)': 'HPS',
  'Hospice & Palliative Medicine': 'HPM',
  'Hospitalist': 'HOS',
  'Immunology': 'IG',
  'Immunopathology': 'PIP',
  'In Vivo & In Vitro Nuclear Medicine': 'NV',
  'Infectious Disease': 'ID',
  'Internal Med-Emergency Med': 'MEM',
  'Internal Medicine-Preventive Medicine': 'IPM',
  'Internal Medicine-Psychiatry': 'MP',
  'Internal Medicine': 'IM',
  'Internal Medicine/Family Practice': 'IFP',
  'Internal Medicine/Medical Genetics': 'MDG',
  'Internal Medicine/Neurology': 'MN',
  'Internal Medicine/Pediatrics': 'MPD',
  'Internal Medicine/Rehabilitation': 'MPM',
  'Interventional Cardiology': 'IC',
  'Interventional Radiology - Independent': 'IRD',
  'Interventional Radiology - Integrated': 'IRI',
  'Learning Disability': 'NDN',
  'Legal Medicine': 'LM',
  'Lipidology': 'LPD',
  'Marriage & Family Therapy': 'MFT',
  'Massage Therapy': 'MT',
  'Maternal & Fetal Medicine': 'MFM',
  'Maxillofacial and Oral Radiology': 'MFR',
  'Maxillofacial Pathology': 'MPH',
  'Maxillofacial Radiology': 'MXR',
  'Medical Biochemical Genetics': 'MBG',
  'Medical Ethics': 'ME',
  'Medical Genetics': 'MG',
  'Medical Management': 'MDM',
  'Medical Microbiology': 'MM',
  'Medical Oncology': 'ON',
  'Medical Physics (Radiology)': 'MDP',
  'Medical Physics': 'MEP',
  'Medical Toxicology (Emergency Medicine)': 'ETX',
  'Medical Toxicology (Pediatrics)': 'PDT',
  'Medical Toxicology (Preventive Medicine)': 'PTX',
  'Mental Health Counselor': 'MHC',
  'Mental Health Practice': 'MHP',
  'Microbiology (Research)': 'MCB',
  'Midwifery': 'MID',
  'MOHS Surgery': 'MOS',
  'Molecular Genetic Pathology (Genetics)': 'MGG',
  'Molecular Genetic Pathology (Pathology)': 'MGP',
  'Multi Specialty Practice': 'MSP',
  'Musculoskeletal Oncology': 'OMO',
  'Musculoskeletal Radiology': 'MSR',
  'Naprapathy': 'NAP',
  'Naturopathic Medicine': 'NAT',
  'Neonatal-Perinatal Medicine': 'NPM',
  'Nephrology': 'NEP',
  'Neurodevelopmental Disabilities (Ped)': 'NDP',
  'Neurointerventional Radiology': 'NIR',
  'Neurologic Oncology': 'NON',
  'Neurological Surgery': 'NS',
  'Neurology': 'N',
  'Neurology/Diagnostic Rad/Neuroradiology': 'NRN',
  'Neurology/Physical Medicine & Rehab': 'NPR',
  'Neuromuscular Medicine (Physical Med)': 'NMP',
  'Neuromuscular Medicine': 'NMN',
  'Neuromusculoskeletal Medicine': 'NMO',
  'Neuropathology': 'NP',
  'Neuropsychiatry': 'NUP',
  'Neuropsychology': 'PSN',
  'Neuroradiology': 'RNR',
  'Nocturnist': 'NOC',
  'Nuclear Cardiology': 'NC',
  'Nuclear Medicine': 'NM',
  'Nuclear Radiology': 'NR',
  'Nutrition': 'NTR',
  'Obstetric Anesthesiology': 'OAN',
  'Obstetrics & Gynecology': 'OBG',
  'Obstetrics': 'OBS',
  'Occupational Medicine': 'OM',
  'Occupational Therapy': 'OCT',
  'Ocular Inflammatory Specialist': 'OID',
  'Oncology, Breast': 'ONB',
  'Ophthalmic Plastic & Recon Surgery': 'OPR',
  'Ophthalmology': 'OPH',
  'Optometry': 'OPT',
  'Oral & Maxillofacial Pathology': 'DOP',
  'Oral & Maxillofacial Radiology': 'DOR',
  'Oral & Maxillofacial Surgery (Dentistry)': 'OSG',
  'Oral & Maxillofacial Surgery (Medicine)': 'OMF',
  'Oral Pathology': 'OPA',
  'Oriental Medicine': 'ORI',
  'Orthodontics and Dentofacial Orthopedics': 'ORP',
  'Orthodontics': 'ORT',
  'Orthopedic & Primary Podiatric Medicine': 'POH',
  'Orthopedic Surgery of the Spine': 'OSS',
  'Orthopedic Surgery': 'ORS',
  'Orthopedic Trauma': 'OTR',
  'Osteopathic Manipulative Medicine': 'OMM',
  'Osteopathic Neuromusculoskeletal Medicine': 'ONM',
  'Other Specialty': 'OS',
  'Other': 'OTH',
  'Otolaryngology': 'OTO',
  'Otology': 'OT',
  'Otology/Neurotology': 'NO',
  'Pain Management (Physical Med & Rehab)': 'PMR',
  'Pain Medicine (Anesthesiology)': 'APM',
  'Pain Medicine (Neurology)': 'PMN',
  'Pain Medicine (Phys Med & Rehab)': 'PMP',
  'Pain Medicine (Psychiatry)': 'PPN',
  'Pain Medicine (Residents Only)': 'PME',
  'Pain Medicine': 'PMD',
  'Palliative Medicine': 'PLM',
  'Pathology/Radioisotopic': 'RIP',
  'Pediatric Allergy': 'PDA',
  'Pediatric Anesthesiology (Pediatrics)': 'PAN',
  'Pediatric Cardiology': 'PDC',
  'Pediatric Cardiothoracic Surgery': 'PCS',
  'Pediatric Critical Care Medicine': 'CCP',
  'Pediatric Dermatology': 'PDD',
  'Pediatric Emergency Medicine (EM)': 'PE',
  'Pediatric Emergency Medicine (Ped)': 'PEM',
  'Pediatric Endocrinology': 'PDE',
  'Pediatric Gastroenterology': 'PG',
  'Pediatric Hematology/Oncology': 'PHO',
  'Pediatric Hospital Medicine': 'PHE',
  'Pediatric Infectious Disease': 'PDI',
  'Pediatric Nephrology': 'PN',
  'Pediatric Ophthalmology': 'PO',
  'Pediatric Orthopedics': 'OP',
  'Pediatric Otolaryngology': 'PDO',
  'Pediatric Pathology': 'PP',
  'Pediatric Pulmonology': 'PDP',
  'Pediatric Radiology': 'PDR',
  'Pediatric Rehabilitation Medicine': 'RPM',
  'Pediatric Rheumatology': 'PPR',
  'Pediatric Surgery (Neurology)': 'NSP',
  'Pediatric Surgery': 'PDS',
  'Pediatric Transplant Hepatology': 'PTP',
  'Pediatric Urology': 'UP',
  'Pediatrics': 'PD',
  'Pediatrics/Anesthesiology': 'PDN',
  'Pediatrics/Dermatology (Residents Only)': 'PDM',
  'Pediatrics/Emergency Medicine': 'EMP',
  'Pediatrics/Medical Genetics': 'PMG',
  'Pediatrics/Physical Med and Rehab': 'PPM',
  'Pediatrics/Psychiatry': 'CPP',
  'Pedodontics (Pediatric Dentistry)': 'PED',
  'Periodontics': 'PER',
  'Pharmaceutical Medicine': 'PHM',
  'Phlebology (Venous Disorders)': 'PHL',
  'Physiatry': 'PHY',
  'Physical Medicine & Rehabilitation': 'PM',
  'Physical Therapy': 'PHT',
  'Plastic Surgery Head & Neck (OTO)': 'PSO',
  'Plastic Surgery Head & Neck (PS)': 'PSP',
  'Plastic Surgery Integrated': 'PSI',
  'Plastic Surgery within the Head & Neck': 'PSH',
  'Plastic Surgery': 'PS',
  'Podiatric Dermatology': 'POE',
  'Podiatric Foot Surgery': 'POS',
  'Podiatric Hygiene': 'POI',
  'Podiatric Pediatrics': 'POP',
  'Podiatric Radiology': 'POR',
  'Podiatric Sports Medicine': 'POQ',
  'Podiatry': 'POD',
  'Primary Care Practice': 'PRCP',
  'Procedural Dermatology': 'PRD',
  'Proctology': 'PRO',
  'Prosthodontics': 'PR',
  'Psychiatry': 'P',
  'Psychiatry/Neurology': 'PYN',
  'Psychoanalysis': 'PYA',
  'Psychology': 'PSY',
  'Psychosomatic Medicine': 'PYM',
  'Public Health and General Preventive Med': 'PHP',
  'Public Health': 'PH',
  'Pulmonary Critical Care Medicine': 'PCC',
  'Pulmonary Disease': 'PUD',
  'Radiation Oncology': 'RO',
  'Radiological Physics': 'RP',
  'Radiology': 'R',
  'Reconstructive Rear Foot & Ankle Surgery': 'POT',
  'Rehabilitation Medicine Pediatric': 'PRM',
  'Rehabilitation Psychology': 'PSR',
  'Reproductive Endocrinology and Infertility/Medical Genetics and Genomics': 'RGM',
  'Reproductive Endocrinology': 'REN',
  'Reproductive Medicine (APNs)': 'REP',
  'Research Medicine': 'RES',
  'Residents - Adult Cardiothoracic ANES': 'ACA',
  'Residents - Diag Radiology/Nuclear Med': 'DNN',
  'Residents - Emergency/Family Med': 'EFM',
  'Residents - Internal Medicine & ANES': 'IMA',
  'Residents - Internal Medicine/Derm': 'IMD',
  'Residents - Internal/Nuclear Med': 'INM',
  'Residents - Neurology/Nuclear Med': 'NNM',
  'Residents - Neuromusculoskeletal Med': 'NMM',
  'Residents -IM/EM/Critical Care': 'IEC',
  'Respiratory Care Therapy': 'RCT',
  'Retina Specialist': 'RET',
  'Rheumatology': 'RHU',
  'Rural & Community Health (APNs)': 'RCH',
  'School & College Health (APNs)': 'SCH',
  'Selective Pathology': 'SP',
  'Sleep Medicine (Anesthesiology)': 'SMA',
  'Sleep Medicine (Internal Medicine)': 'SMI',
  'Sleep Medicine (Otolaryngology)': 'SMO',
  'Sleep Medicine (Pediatrics)': 'SMP',
  'Sleep Medicine (Psychiatry & Neurology)': 'SMN',
  'Sleep Medicine': 'SME',
  'Social Work': 'SW',
  'Speech & Language Pathology': 'SLP',
  'Spinal Cord Injury Medicine': 'SCI',
  'Sports Medicine - General': 'SPO',
  'Sports Medicine (Emergency Medicine)': 'ESM',
  'Sports Medicine (Family Medicine)': 'FSM',
  'Sports Medicine (Internal Medicine)': 'ISM',
  'Sports Medicine (Orthopedic Surgery)': 'OSM',
  'Sports Medicine (Pediatrics)': 'PSM',
  'Sports Medicine (Physical Med & Rehab)': 'PRS',
  'Sports Medicine': 'PMM',
  'Surgery/Cardiothoracic': 'CTS',
  'Surgery/Plastic Surgery': 'SPS',
  'Surgical Critical Care (Surgery)': 'CSS',
  'Surgical Critical Care': 'CCS',
  'Surgical Oncology': 'SO',
  'Therapeutic Radiology': 'TR',
  'Thoracic Surgery-Integrated': 'TSI',
  'Thoracic Surgery': 'TS',
  'Transitional Year Experience': 'TRN',
  'Transitional Year': 'TY',
  'Transplant Cardiology': 'CDT',
  'Transplant Hepatology (Residents Only)': 'THP',
  'Transplant Medicine': 'TPM',
  'Transplant Nephrology': 'NET',
  'Transplant Surgery': 'TTS',
  'Trauma Surgery': 'TRS',
  'Undersea & Hyperbaric Medicine (EM)': 'UME',
  'Undersea & Hyperbaric Medicine (Orthopedic Surgery)': 'UMO',
  'Undersea & Hyperbaric Medicine': 'UM',
  'Unspecified specialty': 'US',
  'Unspecified': 'US2',
  'Urgent Care Medicine': 'UCM',
  'Urogynecology': 'UGY',
  'Urology': 'U',
  'Vascular & Interventional Radiology': 'VIR',
  'Vascular Medicine': 'VM',
  'Vascular Neurology': 'VN',
  'Vascular Surgery-Integrated': 'VSI',
  'Vascular Surgery': 'VS',
  'Vision Practice': 'VISP',
  "Women's Health": 'WH'
};

export const degrees = {
  'Acupuncture Detoxification Specialist': 'ADS',
  'Acupuncture Detoxification Technician': 'ACDT',
  'Acupuncturist': 'AC',
  'Advanced Dental Therapist': 'ADT',
  'Allopathic Medical Student': 'MD-S',
  'Anesthesiology Assistant': 'AA',
  'Animal Medical Technician': 'AMT',
  'Animal Technician': 'AT',
  'Athletic Trainer': 'ATH',
  'Audiologist': 'AUD',
  'Certified Surgical Technologist': 'CST',
  'Dental Assistant': 'DA',
  'Dental Hygienist': 'DH',
  'Dental Laboratory Technician': 'DLT',
  'Denturist': 'DNT',
  'Dietitian/Nutritionist': 'DN',
  'Doctor of Audiology': 'DAUD',
  'Doctor of Chiropractic Medicine': 'DC',
  'Doctor of Dental Medicine': 'DMD',
  'Doctor of Dental Surgery': 'DDS',
  'Doctor of Homeopathic Medicine': 'HMD',
  'Doctor of Medicine': 'MD',
  'Doctor of Naprapathy': 'DON',
  'Doctor of Optometry': 'OD',
  'Doctor of Oriental Medicine': 'DOM',
  'Doctor of Osteopathic Medicine': 'DO',
  'Doctor of Pharmacy': 'PHARMD',
  'Doctor of Philosophy': 'PHD',
  'Doctor of Podiatric Medicine': 'DPM',
  'Doctor of Public Health': 'DRPH',
  'Emergency Medical Services/Technician': 'EMS',
  'Esthetician': 'EST',
  'Family Nurse Practitioner': 'FNP',
  'Health Care Business Professional': 'HBP',
  'Laboratory Assistant': 'LBT',
  'Marriage and Family Therapist': 'MFT',
  'Massage Therapist': 'MT',
  'Master of Public Health': 'MPH',
  'Medical Assistant': 'MA',
  'Mental Health Counselor': 'MHC',
  'Midwife': 'MW',
  'Naturopathic Doctor': 'ND',
  'Nuclear Medicine Technician': 'NMT',
  'Nurse Midwife Furnishing (CA Only)': 'NMF',
  'Nurse Midwife': 'NM',
  'Nurse Practitioner Furnishing (CA Only)': 'NPF',
  'Nurse Practitioner': 'NP',
  'Nursing Assistant/Nurse Aide': 'NA',
  'Occupational Therapist': 'OT',
  'Occupational Therapy Assistant': 'OTA',
  'Oncology Assistant': 'OA',
  'Optician': 'OP',
  'Optometric Assistant': 'OPTA',
  'Optometric/Ophthalmic Technician': 'OPT',
  'Osteopath, Non-physician': 'DO-L',
  'Osteopathic Medical Student': 'DO-S',
  'Perfusionist/Clinical Perfusionist': 'PRF',
  'Pharmacist Rx': 'PHAR',
  'Pharmacist': 'PHARM',
  'Pharmacy Technician': 'PHT',
  'Phlebotomy Technician': 'PBT',
  'Physical Therapist': 'PT',
  'Physical Therapy Assistant': 'PTA',
  'Physician Assistant': 'PA',
  'Podiatry Assistant': 'PODA',
  'Practical/Vocational Nurse': 'PVN',
  'Psychiatric Technician (CA Only)': 'PYT',
  'Psychologist': 'PSY',
  'Radiology Assistant': 'RADA',
  'Registered Nurse': 'RN',
  'Respiratory Therapist': 'RT',
  'Social Worker': 'SW',
  'Speech-Language Pathologist': 'SLP',
  'Speech-Language Pathology Assistant': 'SLPA',
  'Surgical Assistant': 'SA',
  'Therapist, Radiation': 'RADT'
};

let apiUrl = '';

// export const apiUrl = 'http://localhost:8000';

apiUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.speakerprograms.hlxbureau.com'
    : 'http://localhost:8000';

if (process.env.REACT_APP_APIURL !== undefined) {
  apiUrl = process.env.REACT_APP_APIURL;
}

export const baseApiUrl = apiUrl;
